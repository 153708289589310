import { carRentContract } from './docs/contract-car-rent';
import { userAgreement } from './docs/user-agreement';
import { privacyPolicySmallBusiness } from '@/components/docs/privacy-policy-small-business';
import { privacyPolicyBigBusiness } from '@/components/docs/privacy-policy-big-business';
import { guidoPrivacy } from '@/components/docs/guido-privacy';
import { agreementBreaker } from '@/components/docs/agreement-breaker';

export const articles = [
  {
    id: 'contract',
    titleRu: 'Договор',
    title: 'Contract',
    text: '', // get from API,
    isShowInCommonList: true,
  },
  {
    id: 'vehicle-rental-contract',
    url: '',
    titleRu: 'Договор аренды авто',
    title: 'Car rental Contract',
    text: carRentContract,
    isShowInCommonList: true,
    doc: '/terms/vehicle-rental-contract.html',
  },
  {
    id: 'user-agreement',
    titleRu: 'Пользовательское соглашение',
    title: 'User Agreement',
    text: userAgreement,
    isShowInCommonList: true,
    doc: '/terms/user-agreement.html',
  },
  {
    id: 'privacy-policy',
    titleRu: 'Политика в отношении обработки персональных данных',
    title: 'Privacy Policy',
    url: '/terms/privacy-policy.pdf',
    isShowInCommonList: true,

  },
  {
    id: 'privacy-policy-small-business',
    titleRu: 'Согласие на обработку персональных данных',
    title: 'Privacy Policy Small Business',
    text: privacyPolicySmallBusiness,
    isShowInCommonList: false,
  },
  {
    id: 'privacy-policy-big-business',
    titleRu: 'Согласие на обработку персональных данных',
    title: 'Privacy Policy Big Business',
    text: privacyPolicyBigBusiness,
    isShowInCommonList: false,
  },
  {
    id: 'guido-policy',
    titleRu: 'Политика конфиденциальности GUIDO',
    title: 'Guido policy',
    text: guidoPrivacy,
    isShowInCommonList: false,
  },
  {
    id: 'agreement-breaker',
    titleRu: 'Инструкция по расторжению договора',
    title: 'Agreement breaker',
    text: agreementBreaker,
    isShowInCommonList: false,
  },
];

export const articlesby = [
  {
    id: 'contract',
    titleRu: 'Договор',
    title: 'Contract',
    url: '/terms_by/contract-by.html',
    isShowInCommonList: true,
  },
  {
    id: 'user-agreement',
    titleRu: 'Пользовательское соглашение',
    title: 'User Agreement',
    url: '/terms_by/user-agreement-by.html',
    isShowInCommonList: true,
  },
  {
    id: 'personal-data-processing',
    titleRu:
      'Согласие на получение сообщений рекламного и информационного характера',
    title: 'Privacy Policy',
    url: '/terms_by/personal-data-processing-by.html',
    isShowInCommonList: true,
  },
  {
    id: 'explanation-rights-and-consequences',
    titleRu: 'Разъяснения прав и последствий дачи согласия на обработку ПД ',
    title: 'Rights and consequences',
    url: '/terms_by/rights_and_consequences.html',
    isShowInCommonList: true,
  },
  {
    id: 'personal-data-politics',
    titleRu: 'Политика обработки и защиты персональных данных',
    title: 'Privacy Data Politics',
    url: '/terms_by/personal-data-politics-by.html',
    isShowInCommonList: true,
  },
  {
    id: 'agreement-breaker',
    titleRu: 'Инструкция по расторжению договора',
    title: 'Agreement breaker',
    url: '/terms_by/agreement-breaker-by.html',
    isShowInCommonList: false,
  },
  {
    id: 'agreement-spring-drive',
    titleRu: 'Согласие участника рекламной игры «ВЕСЕННИЙ ДРАЙВ»',
    title: 'Agreement spring drive',
    url: '/terms_by/agreement-spring-drive.html',
    isShowInCommonList: true,
  },
  {
    id: 'rules-spring-drive',
    titleRu: 'Правила игры «ВЕСЕННИЙ ДРАЙВ»',
    title: 'Rules spring drive',
    url: '/terms_by/rules-spring-drive.html',
    isShowInCommonList: true,
  },
];
